<template>
  <div class="ScientificList">
    <div class="banner">
        <div class="container">
            <p>科研诚信动态</p>
        </div>
    </div>
    
    <div class="menu">
        <div class="container">
            <span :class="{'active': menuIndex == '1'}" @click="changeMenu('1')">所有动态</span>
            <span :class="{'active': menuIndex == '2'}" @click="changeMenu('2')">核心政策</span>
            <span :class="{'active': menuIndex == '3'}" @click="changeMenu('3')">诚信案例</span>
        </div>
    </div>

    <div class="content">
        <div class="container">
            <a-row>
                <!-- <a-col class="contentL" :md="24" :lg="18" :xl="18"> -->
                <a-col class="contentL" :md="24" :lg="24" :xl="24">
                    <div class="contentLBox" v-if="newsList.length==0">
                        <img src="../../assets/images/none.png" class="none" alt="">
                    </div>
                    <div class="contentLBox" v-if="newsList.length>0">
                        <div class="contentList" v-for="(item,index) in newsList" :key="index">
                            <div><p>{{item.createTimeText.substring(5,10)}}</p><span>{{item.createTimeText.substring(0,4)}}</span></div>
                            <div><p>{{item.noticeTitle}}</p><span>{{item.noticeContent.content}}</span><router-link :to="{path: '/ScientificDetail',query: {id: item.id}}">查看详情</router-link></div>
                        </div>
                        <el-pagination
                              background
                              layout="prev, pager, next"
                              :total="pagination.total"
                              :current-page.sync="pagination.page"
                              :page-size.sync="pagination.pageSize"
                              @size-change="sizeChange"
                              @current-change="currentChange"
                            >
                        </el-pagination>
                    </div>
                </a-col>
                <!-- <a-col class="contentR" :md="24" :lg="6" :xl="6">
                    <div class="contentRBox">
                        <div class="title">科研服务</div>
                        <ul>
                            <li><img src="../../assets/images/scientificIcon01.png" alt=""><span>检测预警服务</span></li>
                            <li><img src="../../assets/images/scientificIcon03.png" alt=""><span>诚信学堂学习</span></li>
                            <li><img src="../../assets/images/scientificIcon02.png" alt=""><span>学术诚信档案</span></li>
                            <li><img src="../../assets/images/scientificIcon04.png" alt=""><span>万方检测</span></li>
                        </ul>
                    </div>
                </a-col> -->
            </a-row>
        </div>
    </div>
  </div>
</template>

<script>
import { queryContentList } from '@/api/EvidenceService'
export default {
  components: {},
  data () {
    return {
        menuIndex: 1,
        newsList: [],
        pagination: {
          total:0,
          page: 1,
          pageSize: 10
        },
    }
  },
  created () {
    this.init();
  },
  methods: {
    changeMenu(index){
        this.pagination.page = 1;
        this.menuIndex = index;
        this.init();
    },
    init(){
      let type = '';
      if(this.menuIndex == '1'){
        type = '';
      }else if(this.menuIndex == '2'){
        type = '1'
      }else if(this.menuIndex == '3'){
        type = '2'
      }
      let params = {
        type: type,
      }
      queryContentList(this.pagination.page,this.pagination.pageSize,params).then(({data}) => {
        // console.log(data);
        for (let i = 0; i < data.list.length; i++) {
          data.list[i].createTimeText= this.dayjs(data.list[i].createTime*1000).format('YYYY/MM/DD HH:mm:ss ');
        }
        this.newsList = data.list
        this.pagination.total=data.total*1;
      })
    },

    sizeChange (pageSize) {
      this.pageSize = pageSize
      this.pageNum = 1
      this.init()
    },
    currentChange (pageNum) {
      // 更新数据
      this.init()
    },

  },
}
</script>

<style lang="scss" scoped>
    .banner{
        background: url(../../assets/images/scientificListBg.png)no-repeat;
        background-size: cover;
        height: 240px;
        p{
            font-size: 32px;
            font-family: 'SourceHanSansCN, SourceHanSansCN';
            font-weight: 400;
            color: #FFFFFF;
            line-height: 240px;
        }
    }
    .menu{
        height: 60px;
        margin-top: 30px;
        span{
            display: inline-block;
            width: 160px;
            height: 60px;
            line-height: 60px;
            font-size: 20px;
            font-family: 'SourceHanSansCN, SourceHanSansCN';
            color: #000000;
            cursor: pointer;
        }
        span.active{
          color: #1353B4;
        }
    }
    .none{
        display: block;
        width: 168px;
        height: 168px;
        margin: 50px auto;
    }
    .content{
        margin-bottom: 30px;
        .contentL{
            .contentLBox{
                background: #FFFFFF;
                box-shadow: 0px 2px 7px 0px rgba(42,45,77,0.12);
                border-radius: 8px;
                padding: 30px 40px;
                .contentList{
                    border-bottom: 1px solid #D7DAE2;
                    padding: 30px 0;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    div:nth-child(1){
                        width: 70px;
                        padding-right: 30px;
                        p{
                        font-size: 26px;
                        // font-family: 'DINAlternate, DINAlternate';
                        font-family: 'DIN';
                        font-weight: 500;
                        color: #333333;
                        line-height: 32px;
                        }
                        span{
                        font-size: 14px;
                        font-family: 'MicrosoftYaHei';
                        color: #808192;
                        line-height: 16px;
                        }
                    }
                    div:nth-child(2){
                        width: calc(100% - 100px);
                        padding-left: 30px;
                        position: relative;
                        p{
                        font-size: 16px;
                        font-family: 'SourceHanSansCN, SourceHanSansCN';
                        font-weight: 500;
                        color: #000000;
                        line-height: 24px;
                        margin-bottom: 10px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        }
                        span{
                        font-size: 14px;
                        font-family: 'SourceHanSansCN, SourceHanSansCN';
                        font-weight: 400;
                        color: #999999;
                        line-height: 24px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        }
                        a{
                        display: block;
                        margin-top: 15px;
                        font-size: 12px;
                        font-family: 'SourceHanSansCN, SourceHanSansCN';
                        font-weight: 400;
                        color: #1353B4;
                        }
                    }
                    div:nth-child(2)::before{
                        content: '';
                        width: 1px;
                        height: 100%;
                        background: #E1E1E1;
                        position: absolute;
                        left: 0;
                        top: 0;
                    }
                }
            }
        }
        .contentR{
            .contentRBox{
                padding-left: 20px;
                .title{
                    font-size: 18px;
                    font-family: 'PingFangSC, PingFang SC';
                    font-weight: 500;
                    color: #2A2D4D;
                    line-height: 20px;
                    margin-bottom: 15px;
                }
                ul{
                    li{
                        height: 100px;
                        background: linear-gradient(270deg, #F0F4FF 0%, #FFFFFF 100%);
                        box-shadow: 0px 2px 10px 0px rgba(48,83,147,0.05);
                        border-radius: 8px;
                        border: 1px solid #E9EBEE;
                        display: flex;
                        align-items: center;
                        padding: 0 40px;
                        margin-bottom: 15px;
                        img{
                            width: 60px;
                            height: 60px;
                            margin-right: 20px;
                        }
                        span{
                            font-size: 18px;
                            font-family: 'SourceHanSansCN, SourceHanSansCN';
                            font-weight: 500;
                            color: #333333;
                            line-height: 30px;
                            white-space: nowrap;
                        }
                    }
                }
            }
        }
    }
    .el-pagination{
        margin-top: 20px;
    }

        // 适配移动端
@media screen and (max-width: 991px){
    .menu{
        span{
            width: 100px;
        }
    }
    .contentLBox{
        padding: 15px 30px!important;
    }
    .contentRBox{
        padding-left: 0!important;
        margin-top: 30px;
    }
}
</style>
